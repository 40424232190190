import { defineComponent, watch, ref } from 'vue';
import { List, Rate } from 'vant';
export default defineComponent({
  components: {
    [List.name]: List,
    [Rate.name]: Rate
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    loading: Boolean,
    finished: Boolean
  },
  emits: ['load', 'click-item'],
  setup(props, {
    emit
  }) {
    const isloading = ref(false);
    const isfinished = ref(false);
    // 列表加载事件
    const onLoadedList = () => {
      // 触发调用加载事件
      emit('load', {});
    };
    // 列表点击事件
    const onTapItem = id => {
      emit('click-item', id);
    };
    watch(() => props.loading, value => {
      isloading.value = value;
    }, {
      immediate: true
    });
    watch(() => props.finished, value => {
      isfinished.value = value;
    }, {
      immediate: true
    });
    return {
      isloading,
      isfinished,
      onLoadedList,
      onTapItem
    };
  },
  methods: {}
});